import cx from "clsx";
import { User } from "kreate-common/modules/business-types";
import Divider from "kreate-common/modules/kreate-ui/components/Divider";

import ArtistCard from "./containers/ArtistCard";
import styles from "./index.module.scss";

import PageLayout from "@/modules/kreate-components/components/PageLayout";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artists: User[] | null | undefined;
};

export default function SectionNewArtists({
  className,
  style,
  artists,
}: Props) {
  return (
    <PageLayout.Section
      paddingBottom="56px"
      className={cx(className, styles.container)}
      style={style}
    >
      <div className={styles.justOnboarded}>{"Trending"}</div>
      <div className={styles.title}>{"Artists"}</div>
      <div style={{ marginTop: "56px" }}>
        {artists?.map((artist, index) => (
          <div key={index}>
            <ArtistCard artist={artist} enableActions={false} />
            {index !== artists.length - 1 ? (
              <Divider.Horizontal className={styles.divider} />
            ) : null}
          </div>
        ))}
      </div>
    </PageLayout.Section>
  );
}
