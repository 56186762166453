import cx from "clsx";

import styles from "./index.module.scss";

type Size = `${number}${"px" | "em"}`;

type Props = {
  style?: React.CSSProperties;
  className?: string;
  size?: Size;
  title?: string;
  color?: "black" | "white";
};

const COLOR_TO_CLASS_NAME = {
  black: styles.colorBlack,
  white: styles.colorWhite,
};

export default function IconSingleEdition({
  className,
  style,
  size = "1.2em",
  title,
  color,
}: Props) {
  return (
    <svg
      className={cx(className, color ? COLOR_TO_CLASS_NAME[color] : null)}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}
      <g filter="url(#filter0_d_7243_6827)">
        <path
          d="M4.1333 11.2157V20.7853C4.13472 21.7265 4.38549 22.6505 4.86009 23.4633C5.33469 24.2761 6.01617 24.9487 6.83517 25.4125L15.3685 30.2407C16.1738 30.6865 17.0792 30.9203 17.9997 30.9203C18.9202 30.9203 19.8256 30.6865 20.6309 30.2407L29.1642 25.4125C29.9833 24.9486 30.6648 24.2761 31.1395 23.4633C31.6142 22.6505 31.8651 21.7265 31.8666 20.7853V11.2157C31.8653 10.2744 31.6145 9.35039 31.1399 8.53759C30.6653 7.72478 29.9838 7.05225 29.1648 6.58847L20.6314 1.75967C19.8286 1.30555 18.9218 1.06689 17.9994 1.06689C17.077 1.06689 16.1703 1.30555 15.3674 1.75967L6.8341 6.58794C6.01526 7.05198 5.33397 7.72467 4.85957 8.53755C4.38516 9.35043 4.13457 10.2745 4.1333 11.2157ZM7.6229 7.98047L16.1562 3.15221C16.7191 2.8357 17.354 2.66944 17.9997 2.66944C18.6454 2.66944 19.2803 2.8357 19.8432 3.15221L28.3765 7.98047C28.9494 8.30457 29.4261 8.77472 29.7581 9.34302C30.0901 9.91133 30.2656 10.5575 30.2666 11.2157V20.7853C30.2655 21.4434 30.09 22.0894 29.758 22.6576C29.426 23.2258 28.9493 23.6959 28.3765 24.0199L19.8432 28.8482C19.2803 29.1647 18.6454 29.3309 17.9997 29.3309C17.354 29.3309 16.7191 29.1647 16.1562 28.8482L7.6229 24.0199C7.05032 23.6957 6.57385 23.2256 6.24194 22.6574C5.91003 22.0892 5.73453 21.4433 5.7333 20.7853V11.2157C5.73444 10.5576 5.90989 9.91151 6.2418 9.34324C6.57372 8.77497 7.05025 8.30477 7.6229 7.98047Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6822 27.9217L8.14885 23.0934C7.74006 22.8623 7.39986 22.5268 7.16292 22.1213C6.92598 21.7159 6.80077 21.2548 6.80005 20.7852V11.2156C6.80062 10.7458 6.92577 10.2847 7.16272 9.87906C7.39966 9.47347 7.73994 9.13797 8.14885 8.90678L16.6822 4.07798C17.0847 3.85174 17.5386 3.73291 18.0003 3.73291C18.462 3.73291 18.916 3.85174 19.3184 4.07798L27.8518 8.90678C28.2606 9.13808 28.6007 9.47362 28.8375 9.8792C29.0744 10.2848 29.1995 10.7459 29.2001 11.2156V20.7852C29.1993 21.2547 29.0742 21.7157 28.8373 22.1212C28.6005 22.5267 28.2604 22.8621 27.8518 23.0934L19.3184 27.9217C18.9158 28.1475 18.4619 28.2661 18.0003 28.2661C17.5387 28.2661 17.0848 28.1475 16.6822 27.9217ZM20.1208 11.8638L17.3083 22.3126H15.9575L18.77 11.8638H20.1208ZM14.3254 12.2729V21.0001H12.7444V13.8112H12.6933L10.6521 15.1152V13.6663L12.8211 12.2729H14.3254ZM24.6379 21.0001V12.2729H23.1336L20.9646 13.6663V15.1152L23.0058 13.8112H23.0569V21.0001H24.6379Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
