import {
  getResourceKey$GetRibbons,
  httpGet$GetRibbons,
} from "kreate-server/commands/GetRibbons/fetcher";
import React from "react";
import useSWR from "swr";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";

export function useSectionEventsFromRibbonApi() {
  const loginStatus = useLoginStatus();
  const getRibbonsSWR = useSWR(
    getResourceKey$GetRibbons(),
    async () => await httpGet$GetRibbons("/api/v1/highlight/ribbons")
  );

  const [activeIndex, setActiveIndex] = React.useState(0);
  const ribbonList = getRibbonsSWR.data?.ribbons?.filter(
    (r) => !r.hidden || (loginStatus?.loggedIn && loginStatus.isAgent)
  );
  const numRibbons = ribbonList?.length || 0;
  const activeRibbon = getRibbonsSWR.data?.ribbons[activeIndex];

  const navigateLeft =
    numRibbons > 1
      ? () => setActiveIndex((index) => (index + numRibbons - 1) % numRibbons)
      : undefined;

  const navigateRight =
    numRibbons > 1
      ? () => setActiveIndex((index) => (index + 1) % numRibbons)
      : undefined;

  return {
    ribbonList,
    numRibbons,
    activeIndex,
    activeRibbon,
    setActiveIndex,
    navigateLeft,
    navigateRight,
  };
}
