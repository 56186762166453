import { Artwork, AuctionBid } from "kreate-common/modules/business-types";
import { z } from "zod";

export const Result = z.object({
  artwork: Artwork.nullish(),
  /** @deprecated use artwork.englishAuction.startedAt instead */
  startAt: z.number().nullish(),
  /** @deprecated use artwork.leadingBid.bidAmount instead */
  highestBid: z.number().nullish(),
  leadingBid: AuctionBid.nullish(),
});

export type Result = z.infer<typeof Result>;

export type GetWeeklyAuctionArtwork$Result = Result;
export const GetWeeklyAuctionArtwork$Result = Result;
