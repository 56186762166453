import { PlatformCountingStats } from "kreate-common/modules/business-types";
import {
  DisplayableError,
  useDisplayableError,
} from "kreate-common/modules/displayable-error";
import { httpGet$PlatformCountingStats } from "kreate-server/commands/PlatformCountingStats/fetcher";
import useSWR from "swr";

export function usePlatformCountingStats(): [
  PlatformCountingStats | undefined,
  DisplayableError | undefined
] {
  const { data, error } = useSWR(
    ["c3ab2758-297f-4cee-95ea-802ebb7c672f"],
    async () => await httpGet$PlatformCountingStats("/api/v1/stats/counting")
  );
  const displayableError = useDisplayableError(
    error,
    "Failed to fetch platform counting stats"
  );
  return [data, displayableError];
}
