import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";
import { Threshold, appendAnchorToDoc, truncateDocument } from "./utils/doc";
import { len, parse, render } from "./utils/node";

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
  value: string;
  unstyled: true;
};

function Default({ className, style, value }: BaseProps) {
  const children = React.useMemo(() => render(parse(value)), [value]);

  return (
    <div className={cx(styles.container, className)} style={style}>
      {children}
    </div>
  );
}

function Collapsible({
  className,
  style,
  value,
  threshold = { maxNumCharacters: 200, maxNumBlocks: 3 },
  acceptableThreshold = { maxNumCharacters: 300, maxNumBlocks: 4 },
}: BaseProps & {
  threshold?: Threshold; // limit for truncating, should be smaller than acceptableThreshold
  acceptableThreshold?: Threshold; // to decide whether we should show Read More button
}) {
  const [collapsed, setCollapsed] = React.useState(true);
  const doc = React.useMemo(() => parse(value), [value]);
  const docLength = React.useMemo(() => len(doc), [doc]);

  const docCollapsed = React.useMemo(
    () =>
      appendAnchorToDoc(truncateDocument(doc, threshold), {
        text: "Read more",
        onClick: () => setCollapsed(false),
        withEllipsis: true,
        withSpace: true,
      }),
    [doc, threshold]
  );

  const docExpanded = React.useMemo(
    () =>
      appendAnchorToDoc(doc, {
        text: "See less",
        onClick: () => setCollapsed(true),
        withEllipsis: false,
        withSpace: true,
      }),
    [doc]
  );

  const hasExceededAcceptableThreshold =
    docLength > acceptableThreshold.maxNumCharacters ||
    doc.content.length > acceptableThreshold.maxNumBlocks;

  return (
    <div className={cx(styles.container, className)} style={style}>
      {!hasExceededAcceptableThreshold
        ? render(doc)
        : collapsed
        ? render(docCollapsed)
        : render(docExpanded)}
    </div>
  );
}

const LongTextViewer = { Default, Collapsible };

export default LongTextViewer;
