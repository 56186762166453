import cx from "clsx";
import SizingContainer from "common-utils/components/SizingContainer";
import { Comment, SimpleUser } from "kreate-common/modules/business-types";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import React from "react";

import DashedHorizontalDivider from "../../../../../PageArtwork/containers/ArtworkDetailsViewer/components/DashedHorizontalDivider";
import ScrollArea from "../../components/ScrollArea";

import styles from "./index.module.scss";

import LongTextViewer from "@/modules/kreate-components/components/LongTextViewer";
import SecondaryFileViewer from "@/modules/kreate-components/components/SecondaryFileViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import { getLinkToPageProfile } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  comment: Comment;
  commentAuthor: SimpleUser;
  fill: true;
};

export default function ColumnRight({
  className,
  style,
  comment,
  commentAuthor,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex.Col
        key={comment.commentId}
        className={styles.rightContent}
        flexDirection="column"
      >
        <Flex.Cell
          className={styles.commentContainer}
          flex="1 1 0"
          minHeight="0"
        >
          <ScrollArea fill>
            <LongTextViewer.Default
              className={styles.commentContent}
              value={comment.content}
              unstyled
            />
          </ScrollArea>
        </Flex.Cell>
        <Flex.Cell flex="0 0 auto" padding="0 56px">
          <DashedHorizontalDivider />
        </Flex.Cell>
        <Flex.Row
          className={styles.commentAuthor}
          padding="32px 56px"
          flex="0 0 auto"
          gap="12px"
          alignItems="center"
        >
          <Flex.Cell asChild flex="0 0 auto">
            <Link href={getLinkToPageProfile(commentAuthor)}>
              <SizingContainer
                className={styles.avatarContainer}
                sizing={{ type: "fixed", width: "48px", height: "48px" }}
              >
                <SecondaryFileViewer
                  file={commentAuthor.avatar}
                  thumbnailFile={undefined}
                  fill
                  sizes="48px"
                  objectFit="cover"
                />
              </SizingContainer>
            </Link>
          </Flex.Cell>
          <Flex.Col minWidth="0">
            <div className={styles.commentAuthorName}>
              <UserDisplayNameViewerV2 user={commentAuthor} unstyled />
            </div>
            <div
              className={styles.commentAuthorDescription}
              title={commentAuthor.headline || undefined}
            >
              {commentAuthor.headline}
            </div>
          </Flex.Col>
        </Flex.Row>
      </Flex.Col>
    </div>
  );
}
