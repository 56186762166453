import {
  Collection,
  ObjectIdAsHexString,
} from "kreate-common/modules/business-types";
import {
  DisplayableError,
  useDisplayableError,
} from "kreate-common/modules/displayable-error";
import {
  getResourceKey$GetCollection,
  httpGet$GetCollection,
} from "kreate-server/commands/GetCollection/fetcher";
import useSWR from "swr";

export function useCollection(
  collectionId: ObjectIdAsHexString | null | undefined
): [Collection | undefined, DisplayableError | undefined] {
  const { data, error } = useSWR(
    collectionId ? getResourceKey$GetCollection({ collectionId }) : undefined,
    () =>
      collectionId
        ? httpGet$GetCollection(`/api/v1/collection/${collectionId}`)
        : undefined
  );

  const displayableError = useDisplayableError(
    error,
    "Failed to fetch collection"
  );

  return [data, displayableError];
}
