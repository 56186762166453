import React from "react";

/**
 * Trigger `effect` whenever it is not null. Make sure it is triggered at most once.
 */
export function useEffectOnce(effect: React.EffectCallback | null | undefined) {
  const triggeredRef = React.useRef(false);

  React.useEffect(() => {
    if (!effect || triggeredRef.current) return;
    triggeredRef.current = true;
    return effect();
  });
}
