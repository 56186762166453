import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  margin?: React.CSSProperties["margin"];
};

// NOTE: @kien-kreate: let's move this component to a shared module
export default function DashedHorizontalDivider({
  className,
  style,
  margin,
}: Props) {
  return (
    <hr
      className={cx(styles.container, className)}
      style={{ margin, ...style }}
    ></hr>
  );
}
