import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";
import { getShortenedAddress } from "./utils";

import { EM_DASH } from "@/modules/common-utils/unicode";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  address: string | null | undefined;
  prefixLength: number;
  suffixLength: number;
  unstyled: true;
  as?: "div" | "span";
};

export default function AddressViewer({
  className,
  style,
  address,
  prefixLength,
  suffixLength,
  as = "div",
}: Props) {
  const Component = as;
  const formattedAddress = !address
    ? EM_DASH
    : getShortenedAddress(address, { prefixLength, suffixLength });
  return (
    <Component
      className={cx(styles.container, className)}
      style={style}
      title={address || undefined}
    >
      {formattedAddress}
    </Component>
  );
}
