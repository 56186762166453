import cx from "clsx";
import { DisplayableError } from "kreate-common/modules/displayable-error";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";

import { usePlatformCountingStats } from "./hooks/usePlatformCountingStats";
import styles from "./index.module.scss";

import {
  formatLovelaceAmount$Compact,
  formatLovelaceAmount$Standard,
} from "@/modules/common-utils/format";
import { EM_DASH } from "@/modules/common-utils/unicode";
import ErrorViewer from "@/modules/kreate-components/components/ErrorViewer";
import PageLayout from "@/modules/kreate-components/components/PageLayout";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function SectionStats({ className, style }: Props) {
  const [countingStats, countingStatsError] = usePlatformCountingStats();

  if (countingStatsError) {
    return <ErrorViewer error={countingStatsError} />;
  }

  /** NOTE: thanh-kreate: creating DisplayableError for
   * each null field can help the Sentry catch the error easier
   */
  if (
    countingStats != null &&
    (countingStats?.numArtists == null ||
      countingStats?.numCollectors == null ||
      countingStats?.numArtworks == null ||
      countingStats?.numTransactions == null ||
      countingStats?.numComments == null ||
      countingStats?.totalLovelaceVolume == null)
  ) {
    console.error(
      new DisplayableError({
        title: "Invalid counting stats",
      })
    );
  }

  return (
    <PageLayout.Section
      className={cx(styles.container, className)}
      style={style}
    >
      <Flex.Row justifyContent="center">
        <Flex.Row
          flexWrap="wrap"
          justifyContent="center"
          gap="60px 120px"
          padding="56px 0"
          maxWidth="960px"
        >
          <div>
            <span className={styles.statsNumber}>
              {countingStats?.numArtists || EM_DASH}
            </span>
            <span className={styles.statsLabel}>{"Artists"}</span>
          </div>
          <div>
            <span className={styles.statsNumber}>
              {countingStats?.numCollectors || EM_DASH}
            </span>
            <span className={styles.statsLabel}>{"Collectors"}</span>
          </div>
          <div>
            <span className={styles.statsNumber}>
              {countingStats?.numArtworks || EM_DASH}
            </span>
            <span className={styles.statsLabel}>{"Works"}</span>
          </div>
          {/* <div>
            <span className={styles.statsNumber}>
              {countingStats?.numComments || EM_DASH}
            </span>
            <span className={styles.statsLabel}>{"Comments"}</span>
          </div> */}
          <div>
            <span className={styles.statsNumber}>
              {countingStats?.numTransactions || EM_DASH}
            </span>
            <span className={styles.statsLabel}>{"Transactions"}</span>
          </div>
          <div>
            <span
              className={styles.statsNumber}
              title={formatLovelaceAmount$Standard(
                countingStats?.totalLovelaceVolume
              )}
            >
              {formatLovelaceAmount$Compact(countingStats?.totalLovelaceVolume)}
            </span>
            <span className={styles.statsLabel}>{"Volume"}</span>
          </div>
        </Flex.Row>
      </Flex.Row>
    </PageLayout.Section>
  );
}
