import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import Divider from "kreate-common/modules/kreate-ui/components/Divider";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { CLIENT_ENV } from "kreate-env/client";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { MdGavel } from "react-icons/md";

import BidderViewer from "../../../../../PageArtwork/containers/ArtworkDetailsViewer/containers/PanelPrimaryAcquisition/containers/MethodAuctionEnglish/components/BidderViewer";
import { getAuctionOverview } from "../../../../../PageArtwork/containers/ArtworkDetailsViewer/containers/PanelPrimaryAcquisition/containers/MethodAuctionEnglish/utils";
import AnimatedLayeredImages from "../../components/AnimatedLayeredImages";
import Decorator from "../../components/Decorator";
import Layout from "../../components/Layout";
import LeftSide from "../../components/LeftSide";
import { GetRibbonsResult$RibbonEnglishAuction } from "../../types";

import styles from "./index.module.scss";

import { useIsExpired } from "@/modules/common-hooks/useIsExpired";
import {
  formatLovelaceAmount$Compact,
  formatLovelaceAmount$Standard,
} from "@/modules/common-utils/format";
import { EM_DASH } from "@/modules/common-utils/unicode";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import CountdownTimer from "@/modules/kreate-components/components/CountdownTimer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import IconLive from "@/modules/kreate-components/icons/IconLive";
import IconSingleEdition from "@/modules/kreate-components/icons/IconSingleEdition";
import { getLinkToPageArtwork } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonEnglishAuction;
  fill: true;
};

export default function MainContent$EnglishAuction({
  className,
  style,
  ribbon,
}: Props) {
  const router = useRouter();
  const overview = ribbon.artwork
    ? getAuctionOverview(ribbon.artwork)
    : undefined;
  const isStarted = useIsExpired(overview?.startedAt);
  const isEnded = useIsExpired(overview?.endedAt);
  const artist = ribbon.artwork?.populated?.createdBy;

  return (
    <Layout.Main className={cx(styles.container, className)} style={style}>
      <Layout.Left>
        <LeftSide.Root>
          <LeftSide.BeforeTitle>
            <Flex.Row gap="12px" alignItems="center">
              <IconLive className={styles.colorPrimary} size="32px" />
              <Flex.Cell>{"Live English Auction"}</Flex.Cell>
              {ribbon?.artwork?.totalSupply === 1 ? (
                <>
                  <Divider.Vertical style={{ margin: "8px 0" }} />
                  <IconSingleEdition
                    color="black"
                    title="This piece is unique. Only one Edition exists in circulation."
                    size="32px"
                  />
                </>
              ) : null}
            </Flex.Row>
          </LeftSide.BeforeTitle>
          <LeftSide.Title>
            <div className={styles.titleContent} title={ribbon.artwork?.title}>
              <Link
                href={
                  ribbon.artwork
                    ? getLinkToPageArtwork(ribbon.artwork)
                    : "#disabled"
                }
              >
                {ribbon.artwork?.title || EM_DASH}
              </Link>
            </div>
          </LeftSide.Title>
          <LeftSide.Stats>
            <Flex.Row gap="12px" minWidth="0">
              <Flex.Row>
                <AvatarViewer value={artist?.avatar} size="56px" />
              </Flex.Row>
              <Flex.Col minWidth="0">
                <em>{"Artist"}</em>
                <UserDisplayNameViewerV2 user={artist} unstyled />
              </Flex.Col>
            </Flex.Row>
            <Decorator.VerticalLine
              size="32px"
              variant="dashed"
              opacity="16%"
            />
            <Flex.Row gap="20px" flex="0 0 auto" alignItems="center">
              {isStarted ? (
                ribbon.leadingBid == null ? (
                  <Flex.Col>
                    <Flex.Cell>
                      <em>{"Reserve"}</em>
                    </Flex.Cell>
                    <Flex.Cell>
                      <span
                        title={formatLovelaceAmount$Standard(overview?.reserve)}
                      >
                        {formatLovelaceAmount$Compact(overview?.reserve)}
                      </span>
                    </Flex.Cell>
                  </Flex.Col>
                ) : (
                  <Flex.Col>
                    <Flex.Cell>
                      <em>{isEnded ? "Winning Bid" : "Highest Bid"}</em>
                    </Flex.Cell>
                    <Flex.Cell>
                      <span
                        className={cx(styles.colorPrimary, styles.semiBold)}
                        title={formatLovelaceAmount$Standard(
                          ribbon.leadingBid?.bidAmount
                        )}
                      >
                        {formatLovelaceAmount$Compact(
                          ribbon.leadingBid?.bidAmount
                        )}
                      </span>
                    </Flex.Cell>
                  </Flex.Col>
                )
              ) : (
                <Flex.Col>
                  <Flex.Cell>
                    <em>{"Reserve"}</em>
                  </Flex.Cell>
                  <Flex.Cell>
                    <span
                      className={cx(styles.colorPrimary, styles.semiBold)}
                      title={formatLovelaceAmount$Standard(overview?.reserve)}
                    >
                      {formatLovelaceAmount$Compact(overview?.reserve)}
                    </span>
                  </Flex.Cell>
                </Flex.Col>
              )}
              <Decorator.VerticalLine
                size="32px"
                variant="dashed"
                opacity="16%"
              />
              <Flex.Col>
                <Flex.Cell>
                  <em>{"Min Increment"}</em>
                </Flex.Cell>
                <Flex.Cell>
                  <span
                    title={formatLovelaceAmount$Standard(overview?.increment)}
                  >
                    {formatLovelaceAmount$Compact(overview?.increment)}
                  </span>
                </Flex.Cell>
              </Flex.Col>
              <Decorator.VerticalLine
                size="32px"
                variant="dashed"
                opacity="16%"
              />
              <Flex.Col>
                <Flex.Cell>
                  <em>{"Edition"}</em>
                </Flex.Cell>
                <Flex.Cell>
                  <span>{`${overview?.numAuctionedEditions || EM_DASH}/${
                    ribbon.artwork?.totalSupply || EM_DASH
                  }`}</span>
                </Flex.Cell>
              </Flex.Col>
            </Flex.Row>
          </LeftSide.Stats>
          <LeftSide.Schedule>
            {!overview ? undefined : isEnded ? (
              <Flex.Col gap="24px" justifyContent="center">
                <em>{"Auction ended"}</em>
                {ribbon?.leadingBid ? (
                  <Flex.Row gap="12px" alignItems="center">
                    <Flex.Row flex="0 0 auto" alignItems="center">
                      <AvatarViewer
                        value={
                          ribbon?.leadingBid.populated?.userProfile?.avatar
                        }
                        size="56px"
                      />
                    </Flex.Row>
                    <Flex.Row gap="24px" alignItems="center" minWidth="0">
                      <Flex.Col minWidth="0">
                        <em>{"Auction Winner"}</em>
                        <BidderViewer
                          className={styles.bidderViewerWinner}
                          hideAvatar
                          address={ribbon?.leadingBid.bidderAddress}
                          user={ribbon?.leadingBid.populated?.userProfile}
                          adaHandles={ribbon?.leadingBid.adaHandles}
                        />
                      </Flex.Col>
                    </Flex.Row>
                  </Flex.Row>
                ) : null}
              </Flex.Col>
            ) : isStarted ? (
              <Flex.Col gap="8px">
                <Flex.Cell>
                  <em>{"Bidding closes in:"}</em>
                </Flex.Cell>
                <CountdownTimer
                  className={styles.timer}
                  as="div"
                  expiredAt={overview.endedAt}
                  unstyled
                />
              </Flex.Col>
            ) : (
              <Flex.Col gap="8px">
                <Flex.Cell>
                  <em>{"Coming Soon:"}</em>
                </Flex.Cell>
                <CountdownTimer
                  className={styles.timer}
                  as="div"
                  expiredAt={overview.startedAt}
                  unstyled
                />
              </Flex.Col>
            )}
          </LeftSide.Schedule>
          {!isEnded && isStarted ? (
            <LeftSide.Actions>
              <ButtonPrimary
                size="xl"
                icon={<MdGavel />}
                content="Place Your Bid"
                onClick={async () =>
                  ribbon.artwork
                    ? await router.push(getLinkToPageArtwork(ribbon.artwork))
                    : undefined
                }
              />
              {ribbon.leadingBid ? (
                <>
                  <Decorator.VerticalLine
                    size="32px"
                    variant="dashed"
                    opacity="16%"
                  />
                  <Flex.Col minWidth="0">
                    <Flex.Cell>
                      <em>{"Leader"}</em>
                    </Flex.Cell>
                    <Flex.Row gap="8px" minWidth="0" alignItems="center">
                      <Flex.Cell minWidth="0">
                        <BidderViewer
                          address={ribbon.leadingBid.bidderAddress}
                          user={ribbon.leadingBid.populated?.userProfile}
                          adaHandles={ribbon.leadingBid.adaHandles}
                        />
                      </Flex.Cell>
                    </Flex.Row>
                  </Flex.Col>
                </>
              ) : null}
            </LeftSide.Actions>
          ) : null}
        </LeftSide.Root>
      </Layout.Left>
      <Layout.Right>
        <Link
          href={
            ribbon.artwork ? getLinkToPageArtwork(ribbon.artwork) : "#disabled"
          }
          className={styles.rightContent}
        >
          {ribbon.backgroundLayers ? (
            <AnimatedLayeredImages
              srcList={ribbon.backgroundLayers.map(
                (src) => `https://${CLIENT_ENV.CLOUDFRONT_HOST}${src}`
              )}
              sizes="(min-width: 1200px) 50vw, 100vw"
              fill
            />
          ) : undefined}
        </Link>
      </Layout.Right>
    </Layout.Main>
  );
}
