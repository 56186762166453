import { HORIZONTAL_ELLIPSIS } from "@/modules/common-utils/unicode";

export function getShortenedAddress(
  address: string,
  { prefixLength, suffixLength }: { prefixLength: number; suffixLength: number }
): string {
  return address.length <= prefixLength + suffixLength
    ? address
    : address.slice(0, prefixLength) +
        HORIZONTAL_ELLIPSIS +
        address.slice(-suffixLength);
}
