import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type Length = `${number}${"px" | "em"}`;

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
  size: Length;
};

const VERTICAL_LINE__VARIANT = {
  solid: styles.solid,
  dashed: styles.dashed,
};

/** A vertical line whose width equals to 1px and height is specified by developer */
function VerticalLine({
  className,
  style,
  size,
  variant = "solid",
  opacity,
}: BaseProps & {
  variant?: keyof typeof VERTICAL_LINE__VARIANT;
  opacity?: React.CSSProperties["opacity"];
}) {
  return (
    <div
      className={cx(
        styles.verticalLine,
        className,
        VERTICAL_LINE__VARIANT[variant]
      )}
      style={{ fontSize: size, opacity, ...style }}
    ></div>
  );
}

const Decorator = {
  VerticalLine,
};

export default Decorator;
