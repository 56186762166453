import cx from "clsx";
import SizingContainer from "common-utils/components/SizingContainer";
import { Artwork } from "kreate-common/modules/business-types";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import Divider from "kreate-common/modules/kreate-ui/components/Divider";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { MdSubdirectoryArrowRight } from "react-icons/md";

import PanelLayout$TwoColumns from "../SectionOurPickedComments/components/PanelLayout$TwoColumns";
import { useOrientation } from "../SectionOurPickedComments/components/PanelLayout$TwoColumns/hooks/useOrientation";
import ScrollArea from "../SectionOurPickedComments/components/ScrollArea";

import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import LongTextViewer from "@/modules/kreate-components/components/LongTextViewer";
import SecondaryFileViewer from "@/modules/kreate-components/components/SecondaryFileViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import IconSingleEdition from "@/modules/kreate-components/icons/IconSingleEdition";
import {
  getLinkToPageArtwork,
  getLinkToPageProfile,
} from "@/modules/navigation/utils/getLinkTo";
import { useUser } from "@/modules/resources/hooks/useUser";

const BREAKPOINT = 992;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artwork: Artwork | null | undefined;
};

export default function FeaturedArtwork({ className, style, artwork }: Props) {
  // TODO: Error handling
  const [user, _userError] = useUser(artwork?.createdBy);
  const router = useRouter();

  const orientation = useOrientation({
    breakpoint: BREAKPOINT,
    initialValue: "landscape",
  });

  return (
    <div className={cx(styles.container, className)} style={style}>
      <PanelLayout$TwoColumns
        className={styles.twoColumns}
        orientation={orientation}
        marginCloseness={4}
        ratioResistance={5}
        leftNumShares={1}
        rightNumShares={1}
        targetedAspectRatio={orientation === "landscape" ? 2 / 1 : 1 / 2}
      >
        <PanelLayout$TwoColumns.Left>
          <Flex.Col
            className={styles.column}
            padding="64px 32px"
            minHeight="0"
            boxSizing="border-box"
          >
            <Flex.Row
              gap="12px"
              alignItems="center"
              style={{ marginBottom: "32px" }}
            >
              <div className={styles.label}>{"Artwork Of The Day"}</div>
              {artwork?.totalSupply === 1 ? (
                <>
                  <Divider.Vertical className={styles.divider} />
                  <IconSingleEdition
                    className={styles.iconSingleEdition}
                    color="white"
                    title="This piece is unique. Only one Edition exists in circulation."
                    size="32px"
                  />
                </>
              ) : undefined}
            </Flex.Row>
            <div className={styles.artworkTitleContainer}>
              <Link
                className={styles.artworkTitle}
                href={artwork ? getLinkToPageArtwork(artwork) : "#disabled"}
              >
                {artwork?.title}
              </Link>
            </div>
            <div className={styles.artist}>
              <Link
                className={styles.avatar}
                href={user ? getLinkToPageProfile(user) : "#disabled"}
              >
                <AvatarViewer value={user?.avatar} size="32px" />
              </Link>
              <Flex.Row alignItems="center" gap="12px" minWidth={0}>
                <UserDisplayNameViewerV2
                  user={user}
                  className={styles.nameViewer}
                  unstyled
                />
                <div className={styles.divider}>{"|"}</div>
                <Flex.Cell className={styles.artistStats} flex="0 0 auto">
                  {user?.numTotalArtworks}
                  {" Works"}
                  <span className={styles.dot}>•</span>
                  {artwork ? artwork.lovedBy.length : EM_DASH}
                  {" Loves"}
                </Flex.Cell>
              </Flex.Row>
            </div>
            <Flex.Cell
              flex="1 1 0"
              minHeight="0"
              className={styles.descriptionContainer}
            >
              <ScrollArea fill>
                <LongTextViewer.Default
                  className={styles.description}
                  value={artwork?.description || ""}
                  unstyled
                />
              </ScrollArea>
            </Flex.Cell>
            <Flex.Cell padding="0 32px">
              <ButtonPrimary
                icon={<MdSubdirectoryArrowRight />}
                style={{ marginTop: "32px" }}
                size="xl"
                content="View Artwork"
                onClick={() =>
                  router.push(
                    artwork ? getLinkToPageArtwork(artwork) : `#disabled`
                  )
                }
                disabled={artwork == null}
              />
            </Flex.Cell>
          </Flex.Col>
        </PanelLayout$TwoColumns.Left>
        <PanelLayout$TwoColumns.Right>
          <SizingContainer
            sizing={{ type: "fill" }}
            background={[styles.fileContainerBackground]}
          >
            <Link href={artwork ? getLinkToPageArtwork(artwork) : "#disabled"}>
              <SecondaryFileViewer
                className={styles.fileViewer}
                file={artwork?.file}
                thumbnailFile={artwork?.imageFile}
                sizes="(max-width: 992px) 100vw, 50vw"
                fill
              />
            </Link>
          </SizingContainer>
        </PanelLayout$TwoColumns.Right>
      </PanelLayout$TwoColumns>
    </div>
  );
}
