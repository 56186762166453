import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

/** Anatomy:
 * ```
 * <Layout.Root>
 *   <Layout.Content>
 *     <Layout.Top></Layout.Top>
 *     <Layout.Main>
 *       <Layout.Left></Layout.Left>
 *       <Layout.Right></Layout.Right>
 *     </Layout.Main>
 *     <Layout.Bottom></Layout.Bottom>
 *   </Layout.Content>
 * </Layout.Root>;
 * ```
 */

function Root({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.root, className)} style={style}>
      {children}
    </div>
  );
}

function Content({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.content, className)} style={style}>
      {children}
    </div>
  );
}

function Top({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.top, className)} style={style}>
      {children}
    </div>
  );
}

function Main({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.main, className)} style={style}>
      {children}
    </div>
  );
}

function Left({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.left, className)} style={style}>
      {children}
    </div>
  );
}

function Right({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.right, className)} style={style}>
      {children}
    </div>
  );
}

function Bottom({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.bottom, className)} style={style}>
      {children}
    </div>
  );
}

const Layout = { Root, Content, Top, Main, Left, Right, Bottom };

export default Layout;
