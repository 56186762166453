import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import Divider from "kreate-common/modules/kreate-ui/components/Divider";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { CLIENT_ENV } from "kreate-env/client";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { MdGavel } from "react-icons/md";

import AnimatedLayeredImages from "../../components/AnimatedLayeredImages";
import Decorator from "../../components/Decorator";
import Layout from "../../components/Layout";
import LeftSide from "../../components/LeftSide";
import { GetRibbonsResult$RibbonGachaMinting } from "../../types";

import styles from "./index.module.scss";

import { useIsExpired } from "@/modules/common-hooks/useIsExpired";
import {
  formatInteger$Compact,
  formatInteger$Standard,
  formatLovelaceAmount$Compact,
  formatLovelaceAmount$Standard,
} from "@/modules/common-utils/format";
import { EM_DASH } from "@/modules/common-utils/unicode";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import CountdownTimer from "@/modules/kreate-components/components/CountdownTimer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import IconLive from "@/modules/kreate-components/icons/IconLive";
import IconSingleEdition from "@/modules/kreate-components/icons/IconSingleEdition";
import { getLinkToPageCollection } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonGachaMinting;
  fill: true;
};

// NOTE: this is a quick but dirty solution for displaying
// 1/1 marker. We have to update this when there is a collection in
// carousel events containing all single edition artworks.
// TODO: `collection.isSingleEdition: boolean`
const KNOWN_SINGLE_EDITION_COLLECTION_IDS: string[] = [];

export default function MainContent$GachaMinting({
  className,
  style,
  ribbon,
}: Props) {
  const router = useRouter();
  const isStarted = useIsExpired(ribbon.collection?.gachaMinting?.startedAt);
  const isEnded = useIsExpired(ribbon.collection?.gachaMinting?.endedAt);

  return (
    <Layout.Main className={cx(styles.container, className)} style={style}>
      <Layout.Left>
        <LeftSide.Root>
          <LeftSide.BeforeTitle>
            <Flex.Row gap="12px" alignItems="center">
              <IconLive className={styles.colorPrimary} size="32px" />
              <div>{"Live Gacha Mint"}</div>
              {ribbon.collection &&
              KNOWN_SINGLE_EDITION_COLLECTION_IDS.includes(
                ribbon.collection.collectionId
              ) ? (
                <>
                  <Divider.Vertical style={{ margin: "8px 0" }} />
                  <IconSingleEdition
                    color="black"
                    title="This piece is unique. Only one Edition exists in circulation."
                    size="32px"
                  />
                </>
              ) : null}
            </Flex.Row>
          </LeftSide.BeforeTitle>
          <LeftSide.Title>
            <div
              className={styles.titleContent}
              title={ribbon.collection?.title}
            >
              <Link
                href={
                  ribbon.collection
                    ? getLinkToPageCollection(ribbon.collection)
                    : "#disabled"
                }
              >
                {ribbon.collection?.title || EM_DASH}
              </Link>
            </div>
          </LeftSide.Title>
          <LeftSide.Stats>
            <Flex.Row gap="12px">
              <AvatarViewer
                value={ribbon.collection?.populated?.createdBy.avatar}
                size="56px"
              />
              <Flex.Col>
                <Flex.Cell>
                  <em>{"Artist"}</em>
                </Flex.Cell>
                <UserDisplayNameViewerV2
                  user={ribbon.collection?.populated?.createdBy}
                  unstyled
                />
              </Flex.Col>
            </Flex.Row>
            {isStarted ? (
              <>
                <Decorator.VerticalLine
                  size="32px"
                  variant="dashed"
                  opacity="16%"
                />
                <Flex.Col>
                  <Flex.Cell>
                    <em>{"Tokens"}</em>
                  </Flex.Cell>
                  <Flex.Cell>
                    {formatInteger$Standard(
                      ribbon.collection?.gachaMinting?.totalTokens
                    )}
                  </Flex.Cell>
                </Flex.Col>
              </>
            ) : null}
            <Decorator.VerticalLine
              size="32px"
              variant="dashed"
              opacity="16%"
            />
            <Flex.Col>
              <Flex.Cell>
                <em>{"Price"}</em>
              </Flex.Cell>
              <Flex.Cell>
                <span
                  title={formatLovelaceAmount$Standard(
                    ribbon.collection?.gachaMinting?.editionPrice
                  )}
                >
                  {formatLovelaceAmount$Compact(
                    ribbon.collection?.gachaMinting?.editionPrice
                  )}
                </span>
              </Flex.Cell>
            </Flex.Col>
          </LeftSide.Stats>
          <LeftSide.Schedule>
            {isEnded ? (
              <Flex.Cell>
                <em>{"Gacha mint ended"}</em>
              </Flex.Cell>
            ) : isStarted ? (
              !!ribbon.collection?.gachaMinting?.endedAt && (
                <Flex.Col gap="8px">
                  <Flex.Cell>
                    <em>{"Gacha mint closes in:"}</em>
                  </Flex.Cell>
                  <CountdownTimer
                    className={styles.timer}
                    as="div"
                    expiredAt={ribbon.collection?.gachaMinting?.endedAt}
                    unstyled
                  />
                </Flex.Col>
              )
            ) : (
              <Flex.Col gap="8px">
                <Flex.Cell>
                  <em>{"Coming Soon:"}</em>
                </Flex.Cell>
                <CountdownTimer
                  className={styles.timer}
                  as="div"
                  expiredAt={ribbon.collection?.gachaMinting?.startedAt}
                  unstyled
                />
              </Flex.Col>
            )}
          </LeftSide.Schedule>
          {isStarted ? (
            <LeftSide.Actions>
              <ButtonPrimary
                size="xl"
                icon={<MdGavel />}
                content="Gacha Mint"
                onClick={async () =>
                  ribbon.collection
                    ? await router.push(
                        getLinkToPageCollection(ribbon.collection)
                      )
                    : undefined
                }
              />
              <Decorator.VerticalLine
                size="32px"
                variant="dashed"
                opacity="16%"
              />
              <Flex.Col>
                <Flex.Cell>
                  <em>{"Tokens Available"}</em>
                </Flex.Cell>
                <Flex.Cell>
                  <span
                    title={formatInteger$Standard(
                      ribbon.collection?.gachaMinting?.numRemainingTokens
                    )}
                  >
                    {formatInteger$Compact(
                      ribbon.collection?.gachaMinting?.numRemainingTokens
                    )}
                  </span>
                </Flex.Cell>
              </Flex.Col>
            </LeftSide.Actions>
          ) : null}
        </LeftSide.Root>
      </Layout.Left>
      <Layout.Right>
        <Link
          href={
            ribbon.collection
              ? getLinkToPageCollection(ribbon.collection)
              : "#disabled"
          }
          className={styles.rightContent}
        >
          {ribbon.backgroundLayers ? (
            <AnimatedLayeredImages
              srcList={ribbon.backgroundLayers.map(
                (src) => `https://${CLIENT_ENV.CLOUDFRONT_HOST}${src}`
              )}
              sizes="(min-width: 1200px) 50vw, 100vw"
              fill
            />
          ) : undefined}
        </Link>
      </Layout.Right>
    </Layout.Main>
  );
}
