import cx from "clsx";
import { Artwork } from "kreate-common/modules/business-types";
import CustomButton from "kreate-common/modules/kreate-ui/components/CustomButton";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { FixedSizeList } from "react-window";

import CardArtwork from "./components/CardArtwork";
import styles from "./index.module.scss";

import { useElementSize } from "@/modules/common-hooks/useElementSize";
import PageLayout from "@/modules/kreate-components/components/PageLayout";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artworks: Artwork[] | null | undefined;
};

const Button = CustomButton.withDefaultProps({
  className: styles.button,
  size: [styles.buttonSize],
  color: [styles.buttonColor],
});

const ITEM_WIDTH = 572; // pixels
const ITEM_HEIGHT = 600; // pixels
const ITEM_COUNT = 1000;

export default function SectionOurPickedArtworks({
  className,
  style,
  artworks,
}: Props) {
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(
    null
  );
  const size = useElementSize(containerRef);
  const [listRef, setListRef] = React.useState<FixedSizeList | null>(null);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <PageLayout.Section paddingBottom="56px">
        <Flex.Col gap="16px">
          <p className={styles.beforeHeading}>{"Our Picked"}</p>
          <h2 className={styles.heading}>{"Artworks"}</h2>
        </Flex.Col>
      </PageLayout.Section>
      <div ref={setContainerRef} className={styles.listContainer}>
        <FixedSizeList
          className={styles.list}
          ref={setListRef}
          height={ITEM_HEIGHT}
          width={size ? size.w : 1440}
          itemCount={ITEM_COUNT}
          itemSize={ITEM_WIDTH}
          layout="horizontal"
          initialScrollOffset={ITEM_WIDTH * (ITEM_COUNT / 2 + 0.5)}
        >
          {({ index, style }) => (
            <div style={style}>
              <div className={styles.listItem}>
                {artworks?.length ? (
                  <CardArtwork value={artworks[index % artworks.length]} fill />
                ) : null}
              </div>
            </div>
          )}
        </FixedSizeList>
        <Button
          className={styles.buttonLeft}
          icon={<MdOutlineArrowBackIos />}
          onClick={() => {
            if (!listRef) return;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state: any = listRef.state;
            const scrollOffset = state?.scrollOffset;
            if (typeof scrollOffset !== "number") return;
            listRef.scrollTo(scrollOffset - ITEM_WIDTH);
          }}
        />
        <Button
          className={styles.buttonRight}
          icon={<MdOutlineArrowForwardIos />}
          onClick={() => {
            if (!listRef) return;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state: any = listRef.state;
            const scrollOffset = state?.scrollOffset;
            if (typeof scrollOffset !== "number") return;
            listRef.scrollTo(scrollOffset + ITEM_WIDTH);
          }}
        />
      </div>
    </div>
  );
}
