import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

function Root({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.root, className)} style={style}>
      {children}
    </div>
  );
}

function BeforeTitle({
  className,
  style,
  content,
  children = content,
}: BaseProps) {
  return (
    <div className={cx(styles.beforeTitle, className)} style={style}>
      {children}
    </div>
  );
}

function Title({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.title, className)} style={style}>
      {children}
    </div>
  );
}

function Stats({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.stats, className)} style={style}>
      {children}
    </div>
  );
}

function Schedule({
  className,
  style,
  content,
  children = content,
}: BaseProps) {
  return (
    <div className={cx(styles.schedule, className)} style={style}>
      {children}
    </div>
  );
}

function Actions({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.actions, className)} style={style}>
      {children}
    </div>
  );
}

const LeftSide = {
  Root,
  BeforeTitle,
  Title,
  Stats,
  Schedule,
  Actions,
};

export default LeftSide;
