import cx from "clsx";
import SizingContainer from "common-utils/components/SizingContainer";
import { Artwork } from "kreate-common/modules/business-types";
import CustomButton from "kreate-common/modules/kreate-ui/components/CustomButton";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import React from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import CollectionTitleViewer from "@/modules/kreate-components/components/CollectionTitleViewer";
import SecondaryFileViewer from "@/modules/kreate-components/components/SecondaryFileViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import { getLinkToPageArtwork } from "@/modules/navigation/utils/getLinkTo";
import { useCollection } from "@/modules/resources/hooks/useCollection";
import { useUser } from "@/modules/resources/hooks/useUser";

const Button = CustomButton.withDefaultProps({
  size: [styles.buttonSize],
  color: [styles.buttonColor],
});

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artwork: Artwork;
  fill: true;
  actions: {
    goBack: (() => void) | null | undefined;
    goNext: (() => void) | null | undefined;
  };
};

export default function ColumnLeft({
  className,
  style,
  artwork,
  actions,
}: Props) {
  const [artworkOwner] = useUser(artwork.createdBy);
  const [artworkCollection] = useCollection(artwork.collectionId);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <SizingContainer
        sizing={{ type: "fill" }}
        background={[styles.fileContainerBackground]}
      >
        <SecondaryFileViewer
          file={artwork.file}
          thumbnailFile={artwork.imageFile}
          sizes="(max-width: 767.98px) 100vw, (max-width: 1440px) 60vw, 864px"
          isSingleEdition={artwork.totalSupply === 1}
          fill
        />
        {actions.goBack != null ? (
          <Button
            className={styles.buttonLeft}
            icon={<MdOutlineArrowBackIos />}
            disabled={!actions.goBack}
            onClick={actions.goBack}
          />
        ) : undefined}
        {actions.goNext != null ? (
          <Button
            className={styles.buttonRight}
            icon={<MdOutlineArrowForwardIos />}
            disabled={!actions.goNext}
            onClick={actions.goNext}
          />
        ) : undefined}
        <Flex.Col className={styles.footer} padding="20px 32px" gap="8px">
          <div className={styles.artworkTitle}>
            <Link href={getLinkToPageArtwork(artwork)}>
              {artwork.title || EM_DASH}
            </Link>
          </div>
          <Flex.Row gap="12px" flex="0 1 auto" minWidth="0">
            <Flex.Row
              className={styles.artworkOwner}
              gap="0.25em"
              flex="0 1 auto"
              minWidth="0"
            >
              <Flex.Cell className={styles.artworkOwnerLabel} flex="0 0 auto">
                {"Artist:"}
              </Flex.Cell>
              <Flex.Cell
                className={styles.artworkOwnerValue}
                flex="0 1 auto"
                minWidth="0"
              >
                <UserDisplayNameViewerV2 user={artworkOwner} unstyled />
              </Flex.Cell>
            </Flex.Row>
            <Flex.Cell asChild flex="0 0 auto">
              <hr className={styles.divider} />
            </Flex.Cell>
            <Flex.Row gap="0.25em" flex="0 1 auto" minWidth="0">
              <Flex.Cell
                className={styles.artworkCollectionLabel}
                flex="0 0 auto"
              >
                {"Collection:"}
              </Flex.Cell>
              <Flex.Cell flex="0 1 auto" minWidth="0">
                <CollectionTitleViewer
                  className={styles.artworkCollectionValue}
                  collection={artworkCollection}
                  artist={artworkOwner}
                  isUnsortedCollection={!artwork.collectionId}
                  unstyled
                />
              </Flex.Cell>
            </Flex.Row>
          </Flex.Row>
        </Flex.Col>
      </SizingContainer>
    </div>
  );
}
