import { DisplayableError } from "kreate-common/modules/displayable-error";
import Image from "next/image";

import FeaturedArtwork from "./containers/FeaturedArtwork";
import SectionEventsFromRibbons from "./containers/SectionEventsFromRibbons";
import SectionNewArtists from "./containers/SectionNewArtists";
import SectionOurPickedArtworks from "./containers/SectionOurPickedArtworks";
import SectionOurPickedComments from "./containers/SectionOurPickedComments";
import SectionStats from "./containers/SectionStats";
import TopArtists from "./containers/TopArtists";
import { usePlatformHighlight } from "./hooks/usePlatformHighlight";
import figureBottom from "./images/figure-bottom.png";
import styles from "./index.module.scss";

import ErrorViewer from "@/modules/kreate-components/components/ErrorViewer";
import PageLayout from "@/modules/kreate-components/components/PageLayout";

// TODO: Render a safe homepage even when platform stats are unavailable.
export default function PageHome() {
  const [highlightStats, highlightStatsError] = usePlatformHighlight();

  /** NOTE: thanh-kreate: creating DisplayableError for
   * each null field can help the Sentry catch the error easier
   */
  if (
    highlightStats != null &&
    (highlightStats?.topArtists == null ||
      highlightStats?.featuredArtwork == null ||
      highlightStats?.topArtworks == null ||
      highlightStats?.emergingArtists == null)
  ) {
    console.error(
      new DisplayableError({
        title: "Invalid highlight stats",
      })
    );
  }

  return (
    <PageLayout
      title="Kreate - Web3 Art Community"
      description={
        "Kreate.Art is an All-in-One Art-focused platform. Share, " +
        "publish, sell, auction, and trade your art here with Art " +
        "lovers all over the world! We have a supportive community " +
        "aimed at fostering sustainability, social connections, " +
        "and creative growth. 🌱"
      }
      url="https://kreate.art"
      background="light"
      shouldUseNavbarV3
      makeTransparentUnlessScrolledDown
    >
      <div className={styles.backdropContainer}>
        <div className={styles.backdrop}></div>
      </div>
      <SectionEventsFromRibbons className={styles.sectionEventsFromRibbons} />
      {highlightStatsError ? (
        <ErrorViewer error={highlightStatsError} />
      ) : (
        <>
          <TopArtists
            style={{ marginTop: "-20px" }}
            artists={highlightStats?.topArtists}
          />
          {highlightStats?.featuredArtwork ? (
            // Layout shift is acceptable here because it is below the fold
            <FeaturedArtwork artwork={highlightStats?.featuredArtwork} />
          ) : null}

          <SectionOurPickedArtworks artworks={highlightStats?.topArtworks} />
          <SectionNewArtists artists={highlightStats?.emergingArtists} />
          {highlightStats?.ourPickedComments?.length ? (
            <>
              <PageLayout.Divider />
              <SectionOurPickedComments
                comments={highlightStats?.ourPickedComments}
              />
            </>
          ) : undefined}
        </>
      )}
      <PageLayout.Divider />
      <SectionStats />
      <PageLayout.Divider />
      <div className={styles.containerFigureBottom}>
        <Image
          src={figureBottom}
          alt=""
          className={styles.figureBottom}
          placeholder="blur"
        />
        <Image
          src={figureBottom}
          alt=""
          className={styles.figureBottom}
          placeholder="blur"
        />
      </div>
    </PageLayout>
  );
}
