import cx from "clsx";
import { SimpleUser } from "kreate-common/modules/business-types";
import ButtonTransparentV2 from "kreate-common/modules/kreate-ui/components/ButtonTransparentV2";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import React from "react";
import { MdCopyAll, MdInfoOutline } from "react-icons/md";

import PopoverV2 from "../PopoverV2";

import styles from "./index.module.scss";

import { range } from "@/modules/common-utils/range";
import { EM_DASH } from "@/modules/common-utils/unicode";
import AddressViewer from "@/modules/kreate-components/components/AddressViewer";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import { useToast } from "@/modules/kreate-contexts/ToastContext";
import Chip from "@/modules/kreate-modals/modals/ModalCollectorListViewer/components/Chip";
import { getLinkToPageGalleryCollector } from "@/modules/navigation/utils/getLinkTo";

type AddressBech32 = string;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  address: AddressBech32;
  user: SimpleUser | null | undefined;
  adaHandles: string[] | null | undefined;
  hideAvatar?: boolean;
};

export default function BidderViewer({
  className,
  style,
  address,
  user,
  adaHandles,
  hideAvatar,
}: Props) {
  const { showMessage } = useToast();

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    showMessage({
      color: "success",
      title: "Copied to Clipboard",
    });
  };

  const [showAllAdaHandles, setShowAllAdaHandles] = React.useState(false);
  const numShownAdaHandles = adaHandles?.length
    ? Math.min(adaHandles.length, showAllAdaHandles ? Infinity : user ? 0 : 2)
    : 0;
  const numHiddenAdaHandles = adaHandles?.length
    ? adaHandles.length - numShownAdaHandles
    : 0;
  const shownAdaHandles = range(numShownAdaHandles).map((index) =>
    adaHandles?.[index] ? "$" + adaHandles[index] : EM_DASH
  );

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Flex.Row alignItems="center" gap="4px">
        {!user ? (
          <AddressViewer
            address={address}
            prefixLength={8}
            suffixLength={8}
            unstyled
          />
        ) : (
          <Flex.Row gap="8px" minWidth="0" alignItems="center">
            {!hideAvatar ? (
              <Flex.Row flex="0 0 auto">
                <AvatarViewer size="24px" value={user.avatar} />
              </Flex.Row>
            ) : null}
            <Flex.Cell flex="0 1 auto" minWidth="0" asChild>
              <UserDisplayNameViewerV2 user={user} unstyled />
            </Flex.Cell>
          </Flex.Row>
        )}
        <PopoverV2
          trigger={
            <ButtonTransparentV2
              icon={
                <MdInfoOutline className={styles.colorFgDimmed} size="20px" />
              }
            />
          }
        >
          <Flex.Row
            alignItems="flex-start"
            gap="24px"
            padding="20px"
            maxWidth="420px"
          >
            <Flex.Cell flex="0 0 auto">
              <AvatarViewer size="56px" value={user?.avatar} />
            </Flex.Cell>
            <Flex.Col gap="8px" minWidth="0">
              <Flex.Row className={styles.title} flexWrap="wrap" gap="8px">
                <Flex.Cell flex="0 1 auto" minWidth="0">
                  {!user ? (
                    <span
                      className={cx(
                        styles.popoverDisplayName,
                        styles.popoverAnonymous
                      )}
                    >
                      {"Anonymous"}
                    </span>
                  ) : (
                    <UserDisplayNameViewerV2
                      className={styles.popoverDisplayName}
                      user={user}
                      unstyled
                    />
                  )}
                </Flex.Cell>
                {shownAdaHandles.map((handle, index) => (
                  <Flex.Cell key={index} flex="0 1 auto" minWidth="0" asChild>
                    <Chip
                      title={`${handle} (click to copy to clipboard)`}
                      onClick={() => copyToClipboard(handle)}
                    >
                      {handle}
                    </Chip>
                  </Flex.Cell>
                ))}
                {numHiddenAdaHandles ? (
                  <Chip
                    onClick={() => setShowAllAdaHandles(true)}
                    title={`Show ${numHiddenAdaHandles} more ADA handles`}
                  >
                    {`+${numHiddenAdaHandles}`}
                  </Chip>
                ) : undefined}
              </Flex.Row>
              <Flex.Row alignItems="center" gap="12px">
                <Flex.Row alignItems="center">
                  <div className={styles.textWallet}>{"Wallet: "}</div>
                  <Link
                    href={getLinkToPageGalleryCollector(address)}
                    className={styles.popoverAddress}
                  >
                    <AddressViewer
                      address={address}
                      prefixLength={8}
                      suffixLength={8}
                      unstyled
                    />
                  </Link>
                </Flex.Row>
                <ButtonTransparentV2
                  className={styles.colorFgDimmed}
                  icon={<MdCopyAll size="24px" />}
                  title="Copy address"
                  onClick={async () => {
                    await navigator.clipboard.writeText(address);
                    showMessage({
                      color: "success",
                      title: "Copied to Clipboard",
                    });
                  }}
                />
              </Flex.Row>
            </Flex.Col>
          </Flex.Row>
        </PopoverV2>
      </Flex.Row>
    </div>
  );
}
