import cx from "clsx";
import { User } from "kreate-common/modules/business-types";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import * as React from "react";

import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import { ImageViewer } from "@/modules/kreate-components/components/ImageViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artist: User | null;
};

export default function ArtistCard({ className, style, artist }: Props) {
  return (
    <Link
      scroll={artist != null ? true : false}
      href={artist != null ? `/user/${artist?.userId}` : ""}
      className={cx(styles.container, className)}
      style={style}
    >
      <div className={styles.avatar}>
        <ImageViewer
          value={artist?.avatar}
          sizing="fill"
          objectFit="cover"
          sizes="80px"
        />
      </div>
      <Flex.Col justifyContent="center" minWidth="0">
        <UserDisplayNameViewerV2
          user={artist}
          as="div"
          className={styles.nameViewer}
          unstyled
        />
        <div className={styles.stats}>
          {artist?.numTotalArtworks || EM_DASH}
          {" Works"} <span className={styles.dot}>•</span>
          {artist?.lovedBy.length ?? EM_DASH}
          {" Loves"}
        </div>
      </Flex.Col>
    </Link>
  );
}
