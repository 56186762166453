import cx from "clsx";
import Image from "next/image";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  srcList: string[];
  fill: true;
  sizes: string | undefined;
};

export default function AnimatedLayeredImages({
  className,
  style,
  srcList,
  sizes,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      {srcList.map((src, index) => (
        <Image
          key={index}
          className={styles.image}
          src={src}
          alt=""
          sizes={sizes}
          fill
        />
      ))}
    </div>
  );
}
