import cx from "clsx";
import { CommentHighlight } from "kreate-common/modules/business-types";
import React from "react";

import PanelLayout$TwoColumns from "./components/PanelLayout$TwoColumns";
import { useOrientation } from "./components/PanelLayout$TwoColumns/hooks/useOrientation";
import ColumnLeft from "./containers/ColumnLeft";
import ColumnRight from "./containers/ColumnRight";
import styles from "./index.module.scss";

import PageLayout from "@/modules/kreate-components/components/PageLayout";

const BREAKPOINT = 992;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  comments: CommentHighlight[] | null | undefined;
};

export default function SectionOurPickedComments({
  className,
  style,
  comments,
}: Props) {
  const orientation = useOrientation({
    breakpoint: BREAKPOINT,
    initialValue: "landscape",
  });

  const [index, setIndex] = React.useState<number>(0);
  const activeComment = comments?.[index];

  if (!activeComment) return null;

  return (
    <section className={cx(styles.container, className)} style={style}>
      <PageLayout.Section paddingTop="96px" paddingBottom="96px">
        <p className={styles.beforeHeading}>{"Our Picked"}</p>
        <h2 className={styles.heading}>{"Comments"}</h2>
        <PanelLayout$TwoColumns
          className={styles.twoColumns}
          orientation={orientation}
          marginCloseness={4}
          ratioResistance={5}
          {...(orientation === "landscape"
            ? {
                targetedAspectRatio: 12 / 7,
                leftNumShares: 3,
                rightNumShares: 2,
              }
            : {
                targetedAspectRatio: 5 / 9,
                leftNumShares: 1,
                rightNumShares: 1,
              })}
        >
          <PanelLayout$TwoColumns.Left>
            <ColumnLeft
              key={activeComment.commentId}
              artwork={activeComment.populated.artwork}
              fill
              actions={{
                goBack:
                  comments.length >= 2
                    ? () =>
                        setIndex((index) =>
                          !index ? comments.length - 1 : index - 1
                        )
                    : null,
                goNext:
                  comments.length >= 2
                    ? () =>
                        setIndex((index) =>
                          index === comments.length - 1 ? 0 : index + 1
                        )
                    : null,
              }}
            />
          </PanelLayout$TwoColumns.Left>
          <PanelLayout$TwoColumns.Right>
            <ColumnRight
              key={activeComment.commentId}
              comment={activeComment}
              commentAuthor={activeComment.populated.author}
              fill
            />
          </PanelLayout$TwoColumns.Right>
        </PanelLayout$TwoColumns>
      </PageLayout.Section>
    </section>
  );
}
