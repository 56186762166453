import cx from "clsx";
import {
  SimpleCollection,
  SimpleUser,
} from "kreate-common/modules/business-types";
import Link from "next/link";
import React from "react";

import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import {
  getLinkToPageCollection,
  getLinkToPageProfile,
} from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  collection: SimpleCollection | null | undefined;
  artist: SimpleUser | null | undefined;
  isUnsortedCollection: boolean;
  as?: "span";
  unstyled: true;
};

export default function CollectionTitleViewer({
  className,
  style,
  collection,
  artist,
  isUnsortedCollection,
  as,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      {as === "span" ? (
        <span>
          {isUnsortedCollection
            ? artist?.customUnsortedCollectionName || "Unsorted Collection"
            : collection?.title || EM_DASH}
        </span>
      ) : isUnsortedCollection ? (
        <Link href={artist ? getLinkToPageProfile(artist) : "#disabled"}>
          {artist?.customUnsortedCollectionName || "Unsorted Collection"}
        </Link>
      ) : (
        <Link
          href={collection ? getLinkToPageCollection(collection) : "#disabled"}
        >
          {collection?.title || EM_DASH}
        </Link>
      )}
    </div>
  );
}
