import { fromJson } from "kreate-common/modules/json-utils";

import { Result } from "./typing";

export async function httpGet$PlatformHighlight(url: string) {
  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}
