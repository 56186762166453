import * as RxTooltip from "@radix-ui/react-tooltip";
import cx from "clsx";
import { GetRibbons$Result } from "kreate-server/commands/GetRibbons/typing";
import Image from "next/image";
import React from "react";

import figureCatalystVoting from "./assets/figure-catalyst-voting.png";
import styles from "./index.module.scss";

import { ImageViewer } from "@/modules/kreate-components/components/ImageViewer";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbonList: GetRibbons$Result["ribbons"] | null | undefined;
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
};

export default function PageNavigator({
  className,
  style,
  ribbonList,
  activeIndex,
  setActiveIndex,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      {ribbonList?.map((ribbon, index) => {
        const file =
          ribbon.type === "english-auction"
            ? ribbon.artwork?.imageFile || ribbon.artwork?.file
            : ribbon.type === "gacha-minting"
            ? ribbon.collection?.avatar || ribbon.collection?.banner
            : ribbon.type === "curated-collection"
            ? ribbon.collection?.avatar || ribbon.collection?.banner
            : undefined;

        const title =
          ribbon.type === "english-auction"
            ? ribbon.artwork?.title
            : ribbon.type === "gacha-minting"
            ? ribbon.collection?.title
            : ribbon.type === "curated-collection"
            ? ribbon.collection?.title
            : ribbon.type === "catalyst-voting"
            ? "Support our Catalyst Proposal!"
            : undefined;

        return (
          <RxTooltip.Provider key={index}>
            <RxTooltip.Root>
              <RxTooltip.Trigger asChild>
                <button
                  className={cx(
                    styles.button,
                    index === activeIndex ? styles.active : undefined
                  )}
                  onClick={() => setActiveIndex(index)}
                >
                  <div className={styles.buttonContent}>
                    {ribbon.type !== "catalyst-voting" ? (
                      <ImageViewer value={file} sizes="40px" sizing="fill" />
                    ) : (
                      <Image
                        src={figureCatalystVoting}
                        alt=""
                        sizes="40px"
                        fill
                      />
                    )}
                  </div>
                </button>
              </RxTooltip.Trigger>
              <RxTooltip.Portal>
                <RxTooltip.Content
                  className={styles.tooltipContent}
                  sideOffset={5}
                >
                  {title}
                  <RxTooltip.Arrow className={styles.tooltipArrow} />
                </RxTooltip.Content>
              </RxTooltip.Portal>
            </RxTooltip.Root>
          </RxTooltip.Provider>
        );
      })}
    </div>
  );
}
