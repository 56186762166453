import { fromJson } from "kreate-common/modules/json-utils";

import { ResourceKey } from "../../types";

import { Result } from "./typing";

export async function httpGet$GetWeeklyAuctionArtwork(url: string) {
  const response = await fetch(url);
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export function getResourceKey$GetWeeklyAuctionArtwork(): ResourceKey {
  return ["6f2adc19-5b70-4611-b3f8-21c10645647b"];
}
