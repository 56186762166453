import {
  Collection,
  CollectionUrlHandleString,
  ObjectIdAsHexString,
} from "kreate-common/modules/business-types";
import { z } from "zod";

export const Params = z
  .object({
    collectionId: ObjectIdAsHexString.optional(),
    handle: CollectionUrlHandleString.optional(),
  })
  .refine((data) => !!data.collectionId || !!data.handle, {
    message: "Required collectionId or handle",
  });

export type Params = z.infer<typeof Params>;

export type Result = Collection;

export const Result = Collection;

export type GetCollection$Params = Params;
export const GetCollection$Params = Params;
export type GetCollection$Result = Result;
export const GetCollection$Result = Result;
