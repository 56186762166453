import * as RxPopover from "@radix-ui/react-popover";
import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  trigger: React.JSX.Element;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

export default function PopoverV2({
  className,
  style,
  trigger,
  content,
  children = content,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <RxPopover.Root>
        <RxPopover.Trigger asChild>{trigger}</RxPopover.Trigger>
        <RxPopover.Portal>
          <RxPopover.Content
            className={styles.content}
            sideOffset={4}
            side="top"
          >
            {children}
          </RxPopover.Content>
        </RxPopover.Portal>
      </RxPopover.Root>
    </div>
  );
}
