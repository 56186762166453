import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { CLIENT_ENV } from "kreate-env/client";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { MdPermMedia } from "react-icons/md";

import AnimatedLayeredImages from "../../components/AnimatedLayeredImages";
import Layout from "../../components/Layout";
import LeftSide from "../../components/LeftSide";
import { GetRibbonsResult$RibbonCuratedCollection } from "../../types";

import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import IconLive from "@/modules/kreate-components/icons/IconLive";
import { getLinkToPageCuratedCollection } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonCuratedCollection;
  fill: true;
};

export default function MainContent$CuratedCollection({
  className,
  style,
  ribbon,
}: Props) {
  const router = useRouter();
  return (
    <Layout.Main className={cx(styles.container, className)} style={style}>
      <Layout.Left>
        <LeftSide.Root>
          <LeftSide.BeforeTitle>
            <Flex.Row gap="12px" alignItems="center">
              <IconLive className={styles.colorFgPrimary} size="32px" />
              <div>{"Curated Collection"}</div>
            </Flex.Row>
          </LeftSide.BeforeTitle>
          <LeftSide.Title>
            <div
              className={styles.titleContent}
              title={ribbon.collection?.title}
            >
              <Link
                href={
                  ribbon.collection
                    ? getLinkToPageCuratedCollection(ribbon.collection)
                    : "#disabled"
                }
              >
                {ribbon.collection?.title || EM_DASH}
              </Link>
            </div>
          </LeftSide.Title>
          <LeftSide.Stats>
            <Flex.Row gap="12px">
              <AvatarViewer
                value={ribbon.collection?.populated?.createdBy.avatar}
                size="56px"
              />
              <Flex.Col>
                <Flex.Cell>
                  <em>{"Artist"}</em>
                </Flex.Cell>
                <UserDisplayNameViewerV2
                  user={ribbon.collection?.populated?.createdBy}
                  unstyled
                />
              </Flex.Col>
            </Flex.Row>
          </LeftSide.Stats>
          <LeftSide.Actions>
            <ButtonPrimary
              size="xl"
              icon={<MdPermMedia />}
              content="View Collection"
              onClick={async () =>
                ribbon.collection
                  ? await router.push(
                      getLinkToPageCuratedCollection(ribbon.collection)
                    )
                  : undefined
              }
            />
          </LeftSide.Actions>
        </LeftSide.Root>
      </Layout.Left>
      <Layout.Right>
        <Link
          href={
            ribbon.collection
              ? getLinkToPageCuratedCollection(ribbon.collection)
              : "#disabled"
          }
          className={styles.rightContent}
        >
          {ribbon.backgroundLayers ? (
            <AnimatedLayeredImages
              srcList={ribbon.backgroundLayers.map(
                (src) => `https://${CLIENT_ENV.CLOUDFRONT_HOST}${src}`
              )}
              sizes="(min-width: 1200px) 50vw, 100vw"
              fill
            />
          ) : undefined}
        </Link>
      </Layout.Right>
    </Layout.Main>
  );
}
