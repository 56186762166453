import { fromJson } from "kreate-common/modules/json-utils";

import { ResourceKey } from "../../types";

import { Params, Result } from "./typing";

export async function httpGet$GetCollection(url: string): Promise<Result> {
  const response = await fetch(url, { method: "GET" });
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export function getResourceKey$GetCollection(
  params: Params | null | undefined
): ResourceKey | undefined {
  if (!params) return undefined;
  return ["5178263b-cdff-40e4-bc4f-8b2a4ea8edbe", params];
}
