import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { CLIENT_ENV } from "kreate-env/client";
import Link from "next/link";
import React from "react";
import { MdHowToVote } from "react-icons/md";

import AnimatedLayeredImages from "../../components/AnimatedLayeredImages";
import Layout from "../../components/Layout";
import LeftSide from "../../components/LeftSide";
import { GetRibbonsResult$RibbonCatalystVoting } from "../../types";

import styles from "./index.module.scss";

import IconLive from "@/modules/kreate-components/icons/IconLive";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonCatalystVoting;
  fill: true;
};

export default function MainContent$CatalystVoting({
  className,
  style,
  ribbon,
}: Props) {
  return (
    <Layout.Main className={cx(styles.container, className)} style={style}>
      <Layout.Left>
        <LeftSide.Root>
          <LeftSide.BeforeTitle>
            <Flex.Row gap="12px" alignItems="center">
              <IconLive className={styles.colorPrimary} size="32px" />
              <Flex.Cell style={{ textTransform: "uppercase" }}>
                {"Help build Kreate for everyone"}
              </Flex.Cell>
            </Flex.Row>
          </LeftSide.BeforeTitle>
          <LeftSide.Title>
            <Link
              href="https://cardano.ideascale.com/c/idea/107662"
              target="_blank"
              rel="noreferrer"
            >
              {"Support our Catalyst Proposal!"}
            </Link>
          </LeftSide.Title>
          <div className={styles.description}>
            {"We're building the tools and platform for Artists and " +
              "Art Lovers like you to share, publish, and collect Art, from " +
              "all over the world! We need YOUR help to grow our community! " +
              "Leave a Comment and Vote on our Proposal now!"}
          </div>
          <LeftSide.Schedule>
            <ButtonPrimary
              size="xl"
              icon={<MdHowToVote />}
              content="Vote Now"
              onClick={() =>
                window.open(
                  "https://cardano.ideascale.com/c/idea/107662",
                  "_blank",
                  "noreferrer"
                )
              }
            />
          </LeftSide.Schedule>
        </LeftSide.Root>
      </Layout.Left>
      <Layout.Right>
        <Link
          href="https://cardano.ideascale.com/c/idea/107662"
          target="_blank"
          rel="noreferrer"
          className={styles.rightContent}
        >
          {ribbon.backgroundLayers ? (
            <AnimatedLayeredImages
              srcList={ribbon.backgroundLayers.map(
                (src) => `https://${CLIENT_ENV.CLOUDFRONT_HOST}${src}`
              )}
              sizes="(min-width: 1200px) 50vw, 100vw"
              fill
            />
          ) : undefined}
        </Link>
      </Layout.Right>
    </Layout.Main>
  );
}
