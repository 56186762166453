import { getResourceKey$GetRibbons } from "kreate-server/commands/GetRibbons/fetcher";
import { handler$GetRibbons } from "kreate-server/commands/GetRibbons/handler";
import { GetRibbons$Result } from "kreate-server/commands/GetRibbons/typing";
import { getResourceKey$GetSessionPreferences } from "kreate-server/commands/GetSessionPreferences/fetcher";
import { handler$GetSessionPreferences } from "kreate-server/commands/GetSessionPreferences/handler";
import { SessionPreferences } from "kreate-server/commands/GetSessionPreferences/typing";
import { getResourceKey$GetWeeklyAuctionArtwork } from "kreate-server/commands/GetWeeklyAuctionArtwork/fetcher";
import { handler$GetWeeklyAuctionArtwork } from "kreate-server/commands/GetWeeklyAuctionArtwork/handler";
import { GetWeeklyAuctionArtwork$Result } from "kreate-server/commands/GetWeeklyAuctionArtwork/typing";
import { getResourceKey$LoginStatus } from "kreate-server/commands/LoginStatus/fetcher";
import { handler$LoginStatus } from "kreate-server/commands/LoginStatus/handler";
import { LoginStatus$Result } from "kreate-server/commands/LoginStatus/typing";
import { GetServerSideProps } from "next/types";
import { SWRConfig, unstable_serialize } from "swr";

import PageHome from "../containers/PageHome";
import {
  formatFallback,
  intentionallyIgnoreError,
  omitUndefinedRecursively,
} from "../utils";

import { getDb } from "@/modules/mongodb";

type Props = {
  loginStatus$Result: LoginStatus$Result | undefined;
  getWeeklyAuctionArtwork$Result: GetWeeklyAuctionArtwork$Result | undefined;
  getSessionPreferences$Result: SessionPreferences | undefined;
  getRibbons$Result: GetRibbons$Result | undefined;
};

export default function Route({
  loginStatus$Result,
  getWeeklyAuctionArtwork$Result,
  getSessionPreferences$Result,
  getRibbons$Result,
}: Props) {
  return (
    <SWRConfig
      value={{
        fallback: formatFallback({
          [unstable_serialize(getResourceKey$LoginStatus())]:
            loginStatus$Result,
          [unstable_serialize(getResourceKey$GetWeeklyAuctionArtwork())]:
            getWeeklyAuctionArtwork$Result,
          [unstable_serialize(getResourceKey$GetSessionPreferences())]:
            getSessionPreferences$Result,
          [unstable_serialize(getResourceKey$GetRibbons())]: getRibbons$Result,
        }),
      }}
    >
      <PageHome />
    </SWRConfig>
  );
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
) => {
  const db = await getDb();

  const [
    loginStatus$Result,
    getWeeklyAuctionArtwork$Result,
    getSessionPreferences$Result,
    getRibbons$Result,
  ] = await Promise.all([
    handler$LoginStatus(db, { token: context.req.cookies["token"] }).catch(
      intentionallyIgnoreError
    ),
    handler$GetWeeklyAuctionArtwork(db).catch(intentionallyIgnoreError),
    handler$GetSessionPreferences(context).catch(intentionallyIgnoreError),
    handler$GetRibbons(db).catch(intentionallyIgnoreError),
  ]);

  return {
    props: omitUndefinedRecursively({
      loginStatus$Result,
      getWeeklyAuctionArtwork$Result,
      getSessionPreferences$Result,
      getRibbons$Result,
    }),
  };
};
