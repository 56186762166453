import * as RxScrollArea from "@radix-ui/react-scroll-area";
import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  fill: true;
};

export default function ScrollArea({
  className,
  style,
  content,
  children = content,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <RxScrollArea.Root className={styles.root}>
        <RxScrollArea.Viewport className={styles.viewport}>
          {children}
        </RxScrollArea.Viewport>
        <RxScrollArea.Scrollbar
          className={styles.scrollbar}
          orientation="vertical"
        >
          <RxScrollArea.Thumb className={styles.thumb} />
        </RxScrollArea.Scrollbar>
        <RxScrollArea.Scrollbar
          className={styles.scrollbar}
          orientation="horizontal"
        >
          <RxScrollArea.Thumb className={styles.thumb} />
        </RxScrollArea.Scrollbar>
        <RxScrollArea.Corner className={styles.corner} />
      </RxScrollArea.Root>
    </div>
  );
}
