import { PlatformHighlight } from "kreate-common/modules/business-types";
import {
  DisplayableError,
  useDisplayableError,
} from "kreate-common/modules/displayable-error";
import { httpGet$PlatformHighlight } from "kreate-server/commands/PlatformHighlight/fetcher";
import useSWR from "swr";

export function usePlatformHighlight(): [
  PlatformHighlight | undefined,
  DisplayableError | undefined
] {
  const { data, error } = useSWR(
    ["b2697213-5586-4fb3-a373-30925ead1a76"],
    async () => await httpGet$PlatformHighlight("/api/v1/stats/highlight")
  );
  const displayableError = useDisplayableError(
    error,
    "Failed to fetch platform highlight"
  );
  return [data, displayableError];
}
