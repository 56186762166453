import cx from "clsx";
import { User } from "kreate-common/modules/business-types";
import * as React from "react";

import ArtistCard from "./components/ArtistCard";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artists: User[] | null | undefined;
};

export default function TopArtists({ className, style, artists }: Props) {
  const firstRow = artists?.slice(0, 8) || new Array(8).fill(null);
  const secondRow = artists?.slice(8, 16) || new Array(8).fill(null);
  return (
    <div className={cx(styles.container, className)} style={style}>
      <div className={styles.firstRowSlide}>
        {firstRow.concat(firstRow).map((artist, idx) => (
          // Hacky key, but artists can actually duplicate in this list!
          <ArtistCard key={idx} artist={artist} />
        ))}
      </div>
      <div className={styles.secondRowSlide}>
        {secondRow.concat(secondRow).map((artist, idx) => (
          // Hacky key, but artists can actually duplicate in this list!
          <ArtistCard key={idx} artist={artist} />
        ))}
      </div>
    </div>
  );
}
