import { Doc, Paragraph } from "../types";

import { take } from "./node";

import { HORIZONTAL_ELLIPSIS } from "@/modules/common-utils/unicode";

export type Threshold = {
  maxNumCharacters: number;
  maxNumBlocks: number;
};

export function appendAnchorToParagraph(
  paragraph: Paragraph,
  {
    text,
    onClick,
    withEllipsis,
    withSpace,
  }: {
    text: string;
    onClick: () => void;
    withEllipsis: boolean;
    withSpace: boolean;
  }
) {
  const content = [...paragraph.content];

  if (content.length) {
    content.push({
      type: "span",
      text: (withEllipsis ? HORIZONTAL_ELLIPSIS : "") + (withSpace ? " " : ""),
    });
  }

  content.push({
    type: "a",
    text,
    attrs: {
      onClick: (event) => {
        event.preventDefault();
        onClick();
      },
    },
  });

  return { ...paragraph, content };
}

export function appendAnchorToDoc(
  doc: Doc,
  {
    text,
    onClick,
    withEllipsis,
    withSpace,
  }: {
    text: string;
    onClick: () => void;
    withEllipsis: boolean;
    withSpace: boolean;
  }
): Doc {
  const paragraphs = doc.content;
  const firstParagraphs: Paragraph[] = paragraphs.slice(0, -1);
  const lastParagraph: Paragraph = paragraphs.length
    ? paragraphs[paragraphs.length - 1]
    : { type: "p", content: [] };
  return {
    type: "doc",
    content: [
      ...firstParagraphs,
      appendAnchorToParagraph(lastParagraph, {
        text,
        onClick,
        withEllipsis,
        withSpace,
      }),
    ],
  } satisfies Doc;
}

export function truncateDocument(doc: Doc, threshold: Threshold) {
  const docTaken = take(doc, threshold.maxNumCharacters);
  const docSliced = {
    ...docTaken,
    content: docTaken.content.slice(0, threshold.maxNumBlocks),
  };
  return docSliced;
}
