import cx from "clsx";
import { Artwork } from "kreate-common/modules/business-types";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import React from "react";

import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import { ImageViewer } from "@/modules/kreate-components/components/ImageViewer";
import SecondaryFileViewer from "@/modules/kreate-components/components/SecondaryFileViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import WithArtworkCensorship from "@/modules/kreate-components/components/WithArtworkCensorship";
import { getLinkToPageArtwork } from "@/modules/navigation/utils/getLinkTo";
import { useUser } from "@/modules/resources/hooks/useUser";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  value: Artwork | null | undefined;
  fill: true;
};

export default function CardArtwork({ className, style, value }: Props) {
  const [artist, _artistError] = useUser(value?.createdBy);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <WithArtworkCensorship
        isNSFW={value?.isNSFW || false}
        flagPhotosensitivity={value?.flagPhotosensitivity || false}
        fill={true}
      >
        <Link
          className={styles.linkArticle}
          href={value ? getLinkToPageArtwork(value) : "#disabled"}
        >
          <SecondaryFileViewer
            file={value?.file}
            thumbnailFile={value?.imageFile}
            objectFit="contain"
            fill
            sizes="536px"
            isSingleEdition={value?.totalSupply === 1}
          />
        </Link>
        <Flex.Col
          className={styles.overlay}
          justifyContent="flex-end"
          padding="24px 32px"
        >
          <Link
            className={styles.linkArticle}
            href={value ? `/artwork/${value.artworkId}` : "#"}
          ></Link>
          <div className={styles.linkArtist}>
            <Flex.Row gap="16px">
              <Flex.Cell className={styles.avatarContainer} flex="0 0 auto">
                <Link href={value ? `/user/${value.createdBy}` : "#"}>
                  <ImageViewer
                    value={artist?.avatar}
                    sizing="fill"
                    sizes="80px"
                  />
                </Link>
              </Flex.Cell>
              <Flex.Col justifyContent="center" minWidth={0}>
                <UserDisplayNameViewerV2
                  user={artist}
                  className={styles.nameViewer}
                  unstyled
                />
                <div className={styles.artistDescription}>
                  <span>{`${artist?.numTotalArtworks || EM_DASH} Works`}</span>
                  <span className={styles.dot}>{"•"}</span>
                  <span>{`${artist?.lovedBy.length ?? EM_DASH} Loves`}</span>
                </div>
              </Flex.Col>
            </Flex.Row>
          </div>
        </Flex.Col>
      </WithArtworkCensorship>
    </div>
  );
}
