import { NON_BREAKING_SPACE } from "../kreate-components/components/AssetViewer$Ada$Compact/constants";

import { ALMOST_EQUAL_TO, HORIZONTAL_ELLIPSIS } from "./unicode";
import { NO_BREAK_SPACE } from "./unicode";
import { ADA_SYMBOL, EN_DASH } from "./unicode";

type LovelaceAmount = number;

const numberFormat$LovelaceAmount$Compact = new Intl.NumberFormat("en-US", {
  notation: "compact",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

/**
 * Format lovelace amount using compact notation with maximum 2 fraction
 * digits, e.g. `12345678000 -> 12.35K ₳`.
 *
 * When using this util, remember to provide a way for viewers to see
 * the full amount, usually by adding `title` property and using
 * `formatLovelaceAmount$Standard`.
 */
export function formatLovelaceAmount$Compact(
  lovelaceAmount: LovelaceAmount | null | undefined,
  { approx }: { approx?: boolean } = {}
): string {
  if (lovelaceAmount == null) return EN_DASH;

  if (lovelaceAmount === 0) {
    return "0" + NO_BREAK_SPACE + ADA_SYMBOL;
  }

  if (lovelaceAmount < 0) {
    return "-" + formatLovelaceAmount$Compact(-lovelaceAmount);
  }

  if (lovelaceAmount > 0 && lovelaceAmount < 5000) {
    // less than 0.005 ADA
    return "<" + "0.01" + NO_BREAK_SPACE + ADA_SYMBOL;
  }

  if (
    lovelaceAmount >= 1e9 &&
    lovelaceAmount < 1e10 &&
    lovelaceAmount % 1e6 === 0
  ) {
    return (
      (approx ? ALMOST_EQUAL_TO + NON_BREAKING_SPACE : "") +
      `${lovelaceAmount / 1e6}` +
      NON_BREAKING_SPACE +
      ADA_SYMBOL
    );
  }

  return (
    (approx ? ALMOST_EQUAL_TO + NON_BREAKING_SPACE : "") +
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    numberFormat$LovelaceAmount$Compact.format(`${lovelaceAmount}E-6` as any) +
    NON_BREAKING_SPACE +
    ADA_SYMBOL
  );
}

const numberFormat$LovelaceAmount$Standard = new Intl.NumberFormat("en-US", {
  notation: "standard",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
});

/**
 * Format lovelace amount using standard notation with maximum 6 fraction
 * digits, e.g. `12345678000 -> 12,345.678 ₳`.
 */

export function formatLovelaceAmount$Standard(
  lovelaceAmount: LovelaceAmount | null | undefined,
  { approx }: { approx?: boolean } = {}
) {
  if (lovelaceAmount == null) return EN_DASH;

  return (
    (approx ? ALMOST_EQUAL_TO + NON_BREAKING_SPACE : "") +
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    numberFormat$LovelaceAmount$Standard.format(`${lovelaceAmount}E-6` as any) +
    NON_BREAKING_SPACE +
    ADA_SYMBOL
  );
}

const numberFormat$Integer$Compact = new Intl.NumberFormat("en-US", {
  notation: "compact",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function formatInteger$Compact(integer: number | null | undefined) {
  if (integer == null) return EN_DASH;
  if (Math.abs(integer) < 10000) {
    return integer.toString();
  }
  return numberFormat$Integer$Compact.format(integer);
}

const numberFormat$Integer$Standard = new Intl.NumberFormat("en-US", {
  notation: "standard",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
});

export function formatInteger$Standard(integer: number | null | undefined) {
  if (integer == null) return EN_DASH;
  return numberFormat$Integer$Standard.format(integer);
}

export function formatAddress$Compact(address: string | null | undefined) {
  if (address == null) return EN_DASH;
  if (address.length <= 16) return address;
  return address.slice(0, 8) + HORIZONTAL_ELLIPSIS + address.slice(-8);
}

export function formatAddress$Standard(address: string | null | undefined) {
  if (address == null) return EN_DASH;
  return address;
}

export function formatHash$Compact(hash: string | null | undefined) {
  if (hash == null) return EN_DASH;
  if (hash.length <= 8) return hash;
  return hash.slice(0, 4) + HORIZONTAL_ELLIPSIS + hash.slice(-4);
}

export function formatHash$Standard(hash: string | null | undefined) {
  if (hash == null) return EN_DASH;
  return hash;
}

const numberFormat$Percentage$Compact = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function formatPercentage$Compact(
  percentage: number | null | undefined
) {
  if (percentage == null) return EN_DASH;
  return numberFormat$Percentage$Compact.format(percentage);
}

const numberFormat$Percentage$Standard = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
});

export function formatPercentage$Standard(
  percentage: number | null | undefined
) {
  if (percentage == null) return EN_DASH;
  return numberFormat$Percentage$Standard.format(percentage);
}
