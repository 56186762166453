import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import * as React from "react";
import { MdVisibilityOff } from "react-icons/md";

import useShowAllNSFW from "./hooks/useShowAllNSFW";
import useShowAllPhotosensitivity from "./hooks/useShowAllPhotosensitivity";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  isNSFW: boolean;
  flagPhotosensitivity: boolean;
  children: React.ReactNode;
  fill?: boolean;
};

export default function WithArtworkCensorship({
  className,
  style,
  isNSFW,
  flagPhotosensitivity,
  children,
  fill,
}: Props) {
  const { showAllNSFW, setShowAllNSFW } = useShowAllNSFW();
  const { showAllPhotosensitivity, setShowAllPhotosensitivity } =
    useShowAllPhotosensitivity();

  const [ignoreFlagNSFW, setIgnoreFlagNSFW] = React.useState(false);
  const [ignoreFlagPhotosensitivity, setIgnoreFlagPhotosensitivity] =
    React.useState(false);

  const shouldWarnNSFW = isNSFW && !showAllNSFW && !ignoreFlagNSFW;
  const shouldWarnPhotosensitivity =
    flagPhotosensitivity &&
    !showAllPhotosensitivity &&
    !ignoreFlagPhotosensitivity;

  return (
    <div
      className={cx(
        className,
        fill ? styles.filledContainer : styles.container
      )}
      style={style}
    >
      {children}
      {shouldWarnNSFW &&
      shouldWarnPhotosensitivity /** warning all, one button */ ? (
        <Flex.Row
          className={styles.censorContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Flex.Row justifyContent="center" gap="16px" padding="12px">
            <Flex.Cell flex="0 0 auto">
              <MdVisibilityOff size="24px" color="white" />
            </Flex.Cell>
            <Flex.Col gap="24px">
              <Flex.Col className={styles.censorText}>
                <div>
                  {
                    "The content of this piece may not be suitable for a general audience."
                  }
                </div>
                <div>
                  {
                    "Photosensitivity caution: This media contains rapidly flashing lights, images, and/or patterns. Viewer discretion is advised."
                  }
                </div>
              </Flex.Col>
              <Flex.Row gap="16px" style={{ marginTop: "8px" }}>
                <button
                  onClick={() => {
                    setIgnoreFlagNSFW(true);
                    setIgnoreFlagPhotosensitivity(true);
                  }}
                  className={styles.buttonUnderline}
                >
                  {"Show This"}
                </button>
              </Flex.Row>
            </Flex.Col>
          </Flex.Row>
        </Flex.Row>
      ) : shouldWarnNSFW /** warning nsfw, two buttons */ ? (
        <Flex.Row
          className={styles.censorContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Flex.Row justifyContent="center" gap="16px" padding="12px">
            <MdVisibilityOff size="24px" color="white" />
            <Flex.Col gap="24px">
              <Flex.Cell className={styles.censorText}>
                {
                  "The content of this piece may not be suitable for a general audience."
                }
              </Flex.Cell>
              <Flex.Row gap="16px" style={{ marginTop: "8px" }}>
                <button
                  onClick={() => {
                    setIgnoreFlagNSFW(true);
                  }}
                  className={styles.buttonUnderline}
                >
                  {"Show This"}
                </button>

                <button
                  onClick={() => setShowAllNSFW(true)}
                  className={styles.buttonUnderline}
                >
                  {"Always Show NSFW"}
                </button>
              </Flex.Row>
            </Flex.Col>
          </Flex.Row>
        </Flex.Row>
      ) : shouldWarnPhotosensitivity /** warning photosensitivity, two buttons */ ? (
        <Flex.Row
          className={styles.censorContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Flex.Row justifyContent="center" gap="16px" padding="12px">
            <MdVisibilityOff size="24px" color="white" />
            <Flex.Col gap="24px">
              <Flex.Cell className={styles.censorText}>
                {
                  "Photosensitivity caution: This media contains rapidly flashing lights, images, and/or patterns. Viewer discretion is advised."
                }
              </Flex.Cell>
              <Flex.Row gap="16px" style={{ marginTop: "8px" }}>
                <button
                  onClick={() => setIgnoreFlagPhotosensitivity(true)}
                  className={styles.buttonUnderline}
                >
                  {"Show This"}
                </button>

                <button
                  onClick={() => setShowAllPhotosensitivity(true)}
                  className={styles.buttonUnderline}
                >
                  {"Always Show Photosensitivity"}
                </button>
              </Flex.Row>
            </Flex.Col>
          </Flex.Row>
        </Flex.Row>
      ) : null}
    </div>
  );
}
