import { useTransition, animated } from "@react-spring/web";
import cx from "clsx";
import ButtonSecondary from "kreate-common/modules/kreate-ui/components/ButtonSecondary";
import React from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import Layout from "./components/Layout";
import MainContent$CatalystVoting from "./containers/MainContent$CatalystVoting";
import MainContent$CuratedCollection from "./containers/MainContent$CuratedCollection";
import MainContent$EnglishAuction from "./containers/MainContent$EnglishAuction";
import MainContent$GachaMinting from "./containers/MainContent$GachaMinting";
import PageNavigator from "./containers/PageNavigator";
import { useSectionEventsFromRibbonApi } from "./hooks";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function SectionEventsFromRibbons({ className, style }: Props) {
  const api = useSectionEventsFromRibbonApi();

  const transitions = useTransition(api.activeIndex, {
    from: { opacity: 0, x: "+100%" },
    enter: { opacity: 1, x: "0%" },
    leave: { opacity: 1, x: "-100%" },
  });

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Layout.Root className={styles.root}>
        <Layout.Content>
          <Layout.Main className={styles.main}>
            {transitions((style, index) => {
              const ribbon = api.ribbonList?.[index];
              return (
                <animated.div className={styles.mainContent} style={style}>
                  {!ribbon ? undefined : ribbon.type === "english-auction" ? (
                    <MainContent$EnglishAuction ribbon={ribbon} fill />
                  ) : ribbon.type === "gacha-minting" ? (
                    <MainContent$GachaMinting ribbon={ribbon} fill />
                  ) : ribbon.type === "curated-collection" ? (
                    <MainContent$CuratedCollection ribbon={ribbon} fill />
                  ) : ribbon.type === "catalyst-voting" ? (
                    <MainContent$CatalystVoting ribbon={ribbon} fill />
                  ) : undefined}
                </animated.div>
              );
            })}
          </Layout.Main>
          <Layout.Bottom>
            <PageNavigator
              ribbonList={api.ribbonList}
              activeIndex={api.activeIndex}
              setActiveIndex={(index) => api.setActiveIndex(index)}
            />
          </Layout.Bottom>
        </Layout.Content>
        <ButtonSecondary
          className={styles.buttonNavigateLeft}
          color="inherit"
          icon={<MdOutlineArrowBackIosNew />}
          size="lg-square"
          onClick={api.navigateLeft}
        />
        <ButtonSecondary
          className={styles.buttonNavigateRight}
          color="inherit"
          icon={<MdOutlineArrowForwardIos />}
          size="lg-square"
          onClick={api.navigateRight}
        />
      </Layout.Root>
    </div>
  );
}
