import React from "react";

import { Node } from "../types";
import { Anchor, Block, Doc, Inline, Span } from "../types";

// 1. Length

export function len(node: Node): number {
  if (!node.content) return node.text.length;
  let result = 0;
  for (const item of node.content) {
    result += len(item);
  }
  return result;
}

// 2. Parse

// https://github.github.com/gfm/#extended-autolink-path-validation
// https://github.com/markedjs/marked/blob/master/src/rules.js#LL298C18-L298C18

type WithLength<T> = T & { length: number };

// https://github.com/markedjs/marked/blob/master/src/Tokenizer.js#L751
function applyExtendedAutolinkPathValidation(text: string) {
  const backpedal =
    /(?:[^?!.,:;*_'"~()&]+|\([^)]*\)|&(?![a-zA-Z0-9]+;$)|[?!.,:;*_'"~)]+(?!$))+/;
  for (let i = 0; i < text.length; i++) {
    const match = backpedal.exec(text);
    if (!match || match[0] === text) break;
    text = match[0];
  }
  return text;
}

// https://github.github.com/gfm/#extended-www-autolink
function parseExtendedWwwAutolink(
  text: string
): WithLength<Anchor> | undefined {
  const regex = /^www\.(?:[a-zA-Z0-9_-]+\.)+(?:[a-zA-Z0-9-]+)[^\s<]*/;
  const cap = regex.exec(text);
  if (!cap) return undefined;
  const match = applyExtendedAutolinkPathValidation(cap[0]);
  const attrs = {
    href: "http://" + match,
    target: "_blank",
    rel: "noreferrer",
  };
  return { type: "a", text: match, length: match.length, attrs };
}

// https://github.github.com/gfm/#extended-url-autolink
function parseExtendedUrlAutolink(
  text: string
): WithLength<Anchor> | undefined {
  const regex = /^https?:\/\/(?:[a-zA-Z0-9_-]+\.)+(?:[a-zA-Z0-9-]+)[^\s<]*/;
  const cap = regex.exec(text);
  if (!cap) return undefined;
  const match = applyExtendedAutolinkPathValidation(cap[0]);
  const attrs = {
    href: match,
    target: "_blank",
    rel: "noreferrer",
  };
  return { type: "a", text: match, length: match.length, attrs };
}

// https://github.github.com/gfm/#extended-email-autolink
function parseExtendedEmailAutolink(
  text: string
): WithLength<Anchor> | undefined {
  // https://github.com/markedjs/marked/blob/master/src/rules.js#L296
  const regex =
    /^[A-Za-z0-9._+-]+(@)[a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-_]*[a-zA-Z0-9])+(?![-_])/;
  const cap = regex.exec(text);
  if (!cap) return undefined;
  const match = cap[0];
  const attrs = {
    href: "mailto:" + match,
    target: "_blank",
    rel: "noreferrer",
  };
  return { type: "a", text: match, length: match.length, attrs };
}

// https://github.github.com/gfm/#autolinks-extension-
// We implement "extended www autolink", "extended url autolink", "extended
// email autolink", and ignore "extended protocol autolink"
function parseExtendedAutolink(text: string): WithLength<Anchor> | undefined {
  return (
    parseExtendedWwwAutolink(text) ||
    parseExtendedUrlAutolink(text) ||
    parseExtendedEmailAutolink(text)
  );
}

function parseText(text: string): WithLength<Span> | undefined {
  const regex = /^(\w+|.)/;
  const cap = regex.exec(text);
  if (!cap) return undefined;
  const match = cap[0];
  return { type: "span", text: match, length: match.length };
}

function parseInline(text: string): WithLength<Inline> | undefined {
  return parseExtendedAutolink(text) || parseText(text);
}

function parseBlock(text: string): WithLength<Block> {
  const content: Inline[] = [];
  const raw = text;
  for (let i = text.length; i > 0; i--) {
    const item = parseInline(text);
    if (!item) break;
    // just an optimization, we merge adjacent spans if possible
    if (
      item.type === "span" &&
      content.length &&
      content[content.length - 1].type === "span"
    ) {
      const merged = content[content.length - 1].text + item.text;
      content[content.length - 1] = { type: "span", text: merged };
    } else {
      content.push(item);
    }
    text = text.slice(item.length);
  }
  return { type: "p", content, length: raw.length };
}

export function parseDoc(text: string): WithLength<Doc> {
  const content: Block[] = text.split("\n").map((line) => parseBlock(line));
  return { type: "doc", content, length: text.length };
}

export function parse(text: string): Doc {
  return parseDoc(text);
}

// 3. Render

export function render(node: Node, key?: React.Key | null | undefined) {
  switch (node.type) {
    case "span":
      return (
        <span key={key} {...node.attrs}>
          {node.text}
        </span>
      );
    case "a":
      return (
        <a key={key} {...node.attrs}>
          {node.text}
        </a>
      );
    case "p":
      return (
        <p key={key}>
          {node.content.map((item, index) => render(item, index))}
        </p>
      );
    case "doc":
      return (
        <React.Fragment key={key}>
          {node.content.map((item, index) => render(item, index))}
        </React.Fragment>
      );
  }
}

// 4. Take

/**
 * Given a node, return the first N characters.
 */
export function take<T extends Node = Node>(node: T, length: number): T {
  if (!node.content) {
    const newNode: T = { ...node, text: node.text.slice(0, length) };
    return newNode;
  }

  const result: T & { content: Node[] } = { ...node, content: [] };

  let remainingLength = length;
  for (const item of node.content) {
    const itemLength = len(item);
    if (itemLength <= remainingLength) {
      result.content.push(item);
      remainingLength -= itemLength;
    } else {
      result.content.push(take(item, remainingLength));
      remainingLength = 0;
      break;
    }
  }

  return result;
}
